import { VFC } from 'react'
import Link from 'gatsby-link'
import { BaseButtonStyle } from '@/components/CommonButton'
import { LinkButtonProps } from '@/components/types'

export const LinkButton: VFC<LinkButtonProps> = ({ label, link, color, size }) => {
  const isExternal = /^https?/.test(link)

  return isExternal ? (
    <a href={link} target="_blank" rel="noopener">
      <BaseButtonStyle color={color} size={size}>
        {label}
      </BaseButtonStyle>
    </a>
  ) : (
    <Link to={link}>
      <BaseButtonStyle color={color} size={size}>
        {label}
      </BaseButtonStyle>
    </Link>
  )
}
