import { VFC } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from '@/components/Icon'
import { CommonButtonProps } from '@/components/types'
import { breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins } from '@/utils/styleConfig'

export const CommonButton: VFC<CommonButtonProps> = ({
  label,
  type,
  size,
  color,
  outline,
  icon,
  expanded,
  controls,
  onClick,
}) => {
  const iconColor = color === 'theme' ? 'white' : 'theme'

  return (
    <button onClick={onClick} type={type} aria-expanded={expanded} aria-controls={controls}>
      <BaseButtonStyle size={size} color={color} outline={outline} expanded={expanded}>
        {icon && (
          <IconContainer>
            <Icon type={icon} color={iconColor} />
          </IconContainer>
        )}
        {label}
      </BaseButtonStyle>
    </button>
  )
}

CommonButton.defaultProps = {
  type: 'button',
  size: 'stretch',
  color: 'white',
  outline: false,
  icon: undefined,
  expanded: undefined,
  controls: undefined,
}

export const BaseButtonStyle = styled.span<Partial<CommonButtonProps>>`
  ${mixins.hoverButton()}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 0.08em;

  ${({ size }) =>
    size === 'small'
      ? css`
          padding: 0.6rem 1.2rem;
          min-width: 12rem;
          font-size: 1.4rem;
        `
      : css`
          padding: 1rem 2.4rem;
          min-width: 27rem;
          font-size: 1.8rem;
        `}

  ${({ color, outline, expanded }) => {
    switch (color) {
      case 'theme':
        if (outline) {
          return css`
            border: 0.2rem solid ${colorConfig.themeColor};
            background-color: ${expanded ? colorConfig.themeColor : 'transparent'};
            color: ${expanded ? colorConfig.textSubColor : colorConfig.themeColor};
          `
        } else {
          return css`
            border: 0.2rem solid ${colorConfig.themeColor};
            background-color: ${expanded ? 'transparent' : colorConfig.themeColor};
            color: ${expanded ? colorConfig.themeColor : colorConfig.textSubColor};
          `
        }
      case 'white':
        if (outline) {
          return css`
            border: 0.2rem solid ${colorConfig.textSubColor};
            background-color: ${expanded ? colorConfig.textSubColor : 'transparent'};
            color: ${expanded ? colorConfig.themeColor : colorConfig.textSubColor};
          `
        } else {
          return css`
            border: 0.2rem solid ${colorConfig.textSubColor};
            background-color: ${expanded ? 'transparent' : colorConfig.textSubColor};
            color: ${expanded ? colorConfig.textSubColor : colorConfig.themeColor};
          `
        }
      case 'gray':
        if (outline) {
          return css`
            border: 0.2rem solid ${colorConfig.subColor};
            background-color: ${expanded ? colorConfig.subColor : 'transparent'};
            color: ${expanded ? colorConfig.bgColor : colorConfig.subColor};
          `
        } else {
          return css`
            border: 0.2rem solid ${colorConfig.subColor};
            background-color: ${expanded ? 'transparent' : colorConfig.subColor};
            color: ${expanded ? colorConfig.subColor : colorConfig.bgColor};
          `
        }
      default:
        break
    }
  }}

  ${breakpointUp('md')} {
    ${({ size }) =>
      size === 'small'
        ? css`
            padding: 0.8rem 3.2rem;
            min-width: 16.4rem;
            font-size: 1.8rem;
          `
        : css`
            padding: 1rem 4rem;
            min-width: 23.5rem;
            font-size: 2rem;
          `}
  }
`

const IconContainer = styled.span`
  display: block;
  margin-right: 0.6em;
  width: 1em;
  height: 1em;
`
