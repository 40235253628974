// feature
import { useState, useEffect, ChangeEvent, MouseEvent } from 'react'
import Link, { navigate } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '@/store/index'
import { BaseStyle } from '@/utils/baseStyle'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { screenLock } from '@/feature/screenLock'
import { metaData } from '@/feature/staticData'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'
import { loadTypekit } from '@/feature/loadTypekit'

// components
import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Navigation } from '@/components/Navigation'

const Layout = props => {
  const [expanded, setExpanded] = useState(false)
  const [balloon, setShowBalloon] = useState('')

  const handleClick = (): void => {
    setExpanded(!expanded)
    screenLock(!expanded)
  }

  const handleBalloon = (id: string): void => {
    setShowBalloon(id)
  }

  useEffect(() => {
    loadTypekit()
    screenLock(false)
  }, [])

  const routingSelectedCaseTags = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked: boolean = event.currentTarget.checked

    props.clearCaseFilter()

    if (isChecked) {
      props.addCaseFilter(event)
    }

    navigate('/case/')
    handleBalloon('')
  }

  const routingRecruitWithSelectedTab = (event: MouseEvent<HTMLButtonElement>): void => {
    props.setRecruitControlTabs(event)

    navigate(`/recruit/#recruit-anchor`)
    handleBalloon('')
  }

  return (
    <>
      <BaseStyle />
      <BaseContainer>
        <Header expanded={expanded} onClick={handleClick}>
          <HeaderInner>
            <HeaderNavigationList>
              <li>
                <StyledLink to={metaData.company.url}>{metaData.company.title}</StyledLink>
              </li>
              <li
                onMouseEnter={() => handleBalloon(metaData.it.title)}
                onMouseLeave={() => handleBalloon('')}
              >
                <StyledLink to={metaData.it.url}>事業紹介</StyledLink>
                {balloon === metaData.it.title && (
                  <Balloon>
                    <li>
                      <BalloonLink to={metaData.it.url}>{metaData.it.title}</BalloonLink>
                    </li>
                    <li>
                      <BalloonLink to={metaData.display.url}>{metaData.display.title}</BalloonLink>
                    </li>
                  </Balloon>
                )}
              </li>
              <li
                onMouseEnter={() => handleBalloon(metaData.case.title)}
                onMouseLeave={() => handleBalloon('')}
              >
                <StyledLink to={metaData.case.url}>{metaData.case.title}</StyledLink>
                {balloon === metaData.case.title && (
                  <Balloon>
                    <li>
                      <Label>
                        <input
                          onChange={event => routingSelectedCaseTags(event)}
                          type="checkbox"
                          value={metaData.it.title}
                          checked={props.selectedCaseTags.includes(metaData.it.title)}
                        />
                        {metaData.it.title}
                      </Label>
                    </li>
                    <li>
                      <Label>
                        <input
                          onChange={event => routingSelectedCaseTags(event)}
                          type="checkbox"
                          value={metaData.display.title}
                          checked={props.selectedCaseTags.includes(metaData.display.title)}
                        />
                        {metaData.display.title}
                      </Label>
                    </li>
                  </Balloon>
                )}
              </li>
              <li
                onMouseEnter={() => handleBalloon(metaData.recruit.title)}
                onMouseLeave={() => handleBalloon('')}
              >
                <StyledLink to={metaData.recruit.url}>{metaData.recruit.title}</StyledLink>
                {balloon === metaData.recruit.title && (
                  <Balloon>
                    <li>
                      <Label>
                        <button
                          onClick={event => routingRecruitWithSelectedTab(event)}
                          id="newGraduates"
                          type="button"
                        />
                        新卒採用
                      </Label>
                    </li>
                    <li>
                      <Label>
                        <button
                          onClick={event => routingRecruitWithSelectedTab(event)}
                          id="midCareer"
                          type="button"
                        />
                        中途採用
                      </Label>
                    </li>
                  </Balloon>
                )}
              </li>
              <li>
                <StyledLink to={metaData.contact.url}>{metaData.contact.title}</StyledLink>
              </li>
            </HeaderNavigationList>
          </HeaderInner>
        </Header>
        <Navigation expanded={expanded} onClick={handleClick}>
          <NavigationList>
            <li>
              <Link to="/">トップページ</Link>
            </li>
            <li>
              <Link to={metaData.company.url}>{metaData.company.title}</Link>
            </li>
            <li>
              <Link to={metaData.it.url}>{metaData.it.title}</Link>
            </li>
            <li>
              <Link to={metaData.display.url}>{metaData.display.title}</Link>
            </li>
            <li>
              <Link to={metaData.case.url}>{metaData.case.title}</Link>
            </li>
            <li>
              <Link to={metaData.recruit.url}>{metaData.recruit.title}</Link>
            </li>
            <li>
              <Link to={metaData.contact.url}>{metaData.contact.title}</Link>
            </li>
          </NavigationList>
          <TelDefinitionList>
            <TelDefinitionListInner>
              <dt>京都本社TEL</dt>
              <dd>
                <a href="tel:0758116199">075-811-6199</a>
              </dd>
            </TelDefinitionListInner>
            <TelDefinitionListInner>
              <dt>東京営業所TEL</dt>
              <dd>
                <a href="tel:0368588830">03-6858-8830</a>
              </dd>
            </TelDefinitionListInner>
          </TelDefinitionList>
        </Navigation>
        <MainContainer marginTop={props?.marginTop} marginBottom={props?.marginBottom}>
          {props.children}
        </MainContainer>
        <Footer />
      </BaseContainer>
    </>
  )
}

const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  padding-top: 5.4rem;
  background: top / 2.4rem repeat url('/images/bg_sm.png');

  ${breakpointUp('md')} {
    padding-top: 7.5rem;
    background: top / 2.5rem repeat url('/images/bg_sm.png');
  }
`

const HeaderInner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1.6rem;
`

const HeaderNavigationList = styled.ul`
  display: flex;

  > li {
    position: relative;
    font-size: 1.4rem;
    font-weight: bold;
  }

  ${breakpointDown('md')} {
    display: none;
  }
`

const NavigationList = styled.ul`
  font-weight: bold;

  > li {
    padding: 0.8rem 0;

    a {
      display: block;
      width: 100%;
    }
  }
`

const Balloon = styled.ul`
  position: absolute;
  top: 100%;
  left: 50%;
  width: 20rem;
  margin-left: -10rem;
  border: 0.3rem solid ${colorConfig.themeColor};
  background-color: ${colorConfig.themeColor};
  text-align: center;
  color: ${colorConfig.textSubColor};

  > li {
    transition: color 0.1s ease-out, background-color 0.1s ease-out;

    &:hover {
      background-color: ${colorConfig.textSubColor};
      color: ${colorConfig.themeColor};
      transition: color 0.2s ease-out, background-color 0.2s ease-out;
    }
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: -2rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 2rem;
    border: 1rem solid transparent;
    border-bottom: 1rem solid ${colorConfig.themeColor};
  }
`

const StyledLink = styled(Link)`
  ${mixins.hoverText};
  display: block;
  height: 100%;
  padding: 0.8rem;
`

const BalloonLink = styled(Link)`
  display: block;
  padding: 1.4rem 0.8rem;
  width: 100%;
  height: 100%;
`

const Label = styled.label`
  position: relative;
  display: block;
  padding: 1.4rem 0.8rem;
  cursor: pointer;

  > input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`

type MainContainerProps = {
  marginTop?: 0
  marginBottom?: 0
}

const MainContainer = styled.div<MainContainerProps>`
  position: relative;
  z-index: ${zIndex.layout};
  flex-grow: 1;

  ${({ marginTop }) =>
    marginTop !== 0
      ? css`
          margin-top: 8rem;

          ${breakpointUp('md')} {
            margin-top: 12rem;
          }
        `
      : ''}

  ${({ marginBottom }) =>
    marginBottom !== 0
      ? css`
          margin-bottom: 8rem;

          ${breakpointUp('md')} {
            margin-bottom: 12rem;
          }
        `
      : ''}
`

const TelDefinitionList = styled.dl`
  margin-top: 1.6rem;
  background-color: #ed4040;
  padding: 2rem 1.6rem;
`

const TelDefinitionListInner = styled.div`
  font-weight: bold;
  letter-spacing: 0;

  > dt {
    letter-spacing: 0.04em;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  > dd {
    margin-top: -0.4rem;
    font-size: 1.5rem;
  }

  & + & {
    margin-top: 0.8rem;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
