import { VFC } from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { LayoutContainer } from '@/components/LayoutContainer'
import { LinkButton } from '@/components/LinkButton'
import { breakpointDown, breakpointOnly, breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'
import { metaData } from '@/feature/staticData'

export const Footer: VFC = () => (
  <StyledFooter>
    <LayoutContainer>
      <Flex>
        <Inner>
          <Link to="/">
            <LogoContainer>
              <Logo>
                <svg viewBox="0 0 178 108">
                  <path d="M178.000,108.015 L157.241,108.015 L145.335,74.661 C144.615,74.710 143.896,74.769 143.164,74.769 L132.343,74.769 L132.343,107.899 L112.357,108.015 L112.357,30.503 C112.259,13.754 125.903,0.096 142.847,-0.001 L142.847,-0.001 C159.791,-0.099 173.697,13.401 173.796,30.151 L173.796,44.440 C173.796,53.505 169.799,61.619 163.422,67.176 L178.000,108.015 ZM153.773,29.807 C153.738,23.968 148.881,19.264 142.976,19.297 C137.070,19.332 132.309,24.092 132.343,29.929 L132.343,55.469 L143.137,55.469 C149.042,55.469 153.829,50.737 153.829,44.900 L153.773,29.807 ZM86.422,108.015 C80.000,108.015 74.794,102.868 74.794,96.519 C74.794,90.170 80.000,85.024 86.422,85.024 C92.844,85.024 98.050,90.170 98.050,96.519 C98.050,102.868 92.844,108.015 86.422,108.015 ZM76.677,-0.002 L96.680,-0.002 L96.680,74.769 L76.677,74.769 L76.677,-0.002 ZM41.347,74.769 L19.961,74.769 L19.961,108.015 L0.001,108.015 L0.001,30.326 C0.001,13.576 13.737,-0.002 30.681,-0.002 L30.681,-0.002 C47.625,-0.002 61.361,13.576 61.361,30.326 L61.361,108.015 L41.347,108.015 L41.347,74.769 ZM41.347,29.867 C41.347,24.029 36.559,19.297 30.654,19.297 C24.748,19.297 19.961,24.029 19.961,29.867 L19.961,55.485 L41.347,55.485 L41.347,29.867 Z"></path>
                </svg>
              </Logo>
              <Text>
                お客様の「あったらいいな」にお応えし、
                <br />
                世の中の「楽」に貢献する
              </Text>
            </LogoContainer>
          </Link>
          <Company>
            <CompanyName>
              株式会社Ai-R<span>（アイアール）</span>
            </CompanyName>
            <InformationDefinitionList>
              <InformationDefinitionListInner>
                <dt>本社</dt>
                <dd>〒604-8492 京都府京都市中京区西ノ京右馬寮町17-3</dd>
              </InformationDefinitionListInner>
              <InformationDefinitionListInner>
                <dt>東京営業所</dt>
                <dd>〒108-0073 東京都港区三田3-1-3 M・Kビル 7階</dd>
              </InformationDefinitionListInner>
            </InformationDefinitionList>
          </Company>
        </Inner>
        <Inner>
          <LinkList>
            <li>
              <Link to={metaData.company.url}>会社概要</Link>
            </li>
            <li>
              <Link to={metaData.it.url}>情報機器事業部</Link>
            </li>
            <li>
              <Link to={metaData.display.url}>ディスプレイ事業部</Link>
            </li>
            <li>
              <Link to={metaData.case.url}>事例紹介</Link>
            </li>
            <li>
              <Link to={metaData.recruit.url}>採用情報</Link>
            </li>
            <li>
              <Link to={metaData.yougo.url}>用語集</Link>
            </li>
            <li>
              <Link to={metaData.privacy.url}>プライバシーポリシー</Link>
            </li>
            <li>
              <Link to={metaData.sitemap.url}>サイトマップ</Link>
            </li>
            <li>
              <LinkButtonContainer>
                <LinkButton
                  label="お問い合わせ"
                  link={metaData.contact.url}
                  color="white"
                  size="small"
                />
              </LinkButtonContainer>
            </li>
          </LinkList>
          <SnsButtonContainer>
            <SnsLink href="https://www.instagram.com/ai.r_saiyo/" target="_blank" rel="noopener">
              <img src="/images/icon_instagram.png" alt="Instagram" />
            </SnsLink>
            <SnsLink
              href="https://www.youtube.com/channel/UCNttPToA_RWl4vXlIvN3LXg"
              target="_blank"
              rel="noopener"
            >
              <img src="/images/icon_youtube.svg" alt="YouTube" />
            </SnsLink>
            <SnsLink
              href="https://www.facebook.com/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BEAi-R-102870414948348"
              target="_blank"
              rel="noopener"
            >
              <img src="/images/icon_facebook.svg" alt="Facebook" />
            </SnsLink>
          </SnsButtonContainer>
          <Copyright>Copyright © Ai-R Co.,Ltd. All Rights Reserved.</Copyright>
        </Inner>
      </Flex>
    </LayoutContainer>
  </StyledFooter>
)

const StyledFooter = styled.footer`
  position: relative;
  z-index: ${zIndex.footer};
  padding: 8rem 0 4rem;
  background-color: ${colorConfig.themeColor};
  color: ${colorConfig.textSubColor};

  ${breakpointOnly('md')} {
    padding: 4rem;
  }

  ${breakpointUp('lg')} {
    padding: 8rem 0;
  }
`

const LogoContainer = styled.div`
  ${mixins.hoverDefault()};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${breakpointDown('sm')} {
    justify-content: space-between;
  }
`

const Logo = styled.div`
  flex-shrink: 0;
  width: 6.4rem;
  height: 3.8rem;

  ${breakpointUp('md')} {
    width: 8.8rem;
    height: 5.5rem;
  }

  svg {
    fill: #fff;
  }
`

const Text = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.8;

  ${breakpointDown('sm')} {
    font-size: 1.2rem;
  }

  ${breakpointUp('md')} {
    margin-left: 3.6rem;
  }
`

const Flex = styled.div`
  display: flex;

  ${breakpointDown('md')} {
    flex-direction: column;
  }

  ${breakpointUp('lg')} {
    justify-content: space-between;
  }
`

const Inner = styled.div`
  ${breakpointOnly('md')} {
    margin-top: 4rem;
  }

  &:first-of-type {
    ${breakpointUp('lg')} {
      margin-right: 4rem;
    }
  }

  &:nth-of-type(2) {
    ${breakpointDown('sm')} {
      margin-top: 3.2rem;
    }

    ${breakpointUp('lg')} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`

const Company = styled.div`
  ${breakpointDown('md')} {
    margin-top: 4rem;
  }

  ${breakpointUp('lg')} {
    margin-top: 9.2rem;
  }
`

const CompanyName = styled.div`
  line-height: 1.5;
  font-weight: bold;
  font-size: 2rem;

  span {
    font-size: 1.4rem;
  }
`

const InformationDefinitionList = styled.dl`
  margin-top: 1.6rem;
  font-size: 1.4rem;
`

const InformationDefinitionListInner = styled.div`
  display: flex;

  & + & {
    margin-top: 0.8rem;
  }

  > dt {
    min-width: 9.6rem;
  }
`

const LinkButtonContainer = styled.div`
  margin-top: 0.8rem;
`

const LinkList = styled.ul`
  column-count: 2;
  font-size: 1.6rem;

  ${breakpointDown('sm')} {
    font-size: 1.4rem;
  }

  > li {
    break-inside: avoid;
    border-bottom: 0.8rem solid transparent;

    > a {
      display: inline-block;
      ${mixins.hoverTextUnderLine({ color: '#fff' })};

      ${breakpointDown('sm')} {
        width: 100%;
      }
    }
  }
`

const SnsButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 1.4rem;
  margin-left: auto;

  ${breakpointDown('md')} {
    margin-top: 4rem;
    width: 17.8rem;
    margin-right: auto;
  }

  ${breakpointUp('lg')} {
    margin-top: 2.4rem;
    width: 18.4rem;
  }
`

const SnsLink = styled.a`
  ${mixins.hoverButton};
  border-radius: 9999px;

  /* ${breakpointUp('lg')} {
    width: 5.2rem;
  } */
`

const Copyright = styled.p`
  margin-top: 4rem;
  text-align: right;
  font-size: 1rem;
  letter-spacing: 0;

  ${breakpointDown('md')} {
    text-align: center;
  }
`
