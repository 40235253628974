export const screenLock = (isLock: boolean): void => {
  const body: HTMLBodyElement = document.getElementsByTagName('body')[0]
  const clientWidth = body.clientWidth

  const setScrollBarWidth = () => {
    const noScrollBarWidth = body.clientWidth
    const diff = noScrollBarWidth - clientWidth

    document.documentElement.style.setProperty('--scroll-bar-width', `${diff}px`)
  }

  if (isLock) {
    body.style.overflow = 'hidden'
    setScrollBarWidth()
  } else {
    body.style.overflow = null
    document.documentElement.style.setProperty('--scroll-bar-width', '0px')
  }
}
