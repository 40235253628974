import { VFC } from 'react'
import styled from 'styled-components'
import { IconProps } from '@/components/types'
import { colorConfig } from '@/utils/styleConfig'

const colorControl = {
  theme: colorConfig.themeColor,
  white: '#fff',
  black: '#000',
}

export const Icon: VFC<IconProps> = ({ type, color, degree }) => (
  <IconContainer color={color} degree={degree}>
    {type === 'search' && (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.031 19.031">
        <path
          d="M988.327,32.379a5.948,5.948,0,1,0,5.947,5.947A5.948,5.948,0,0,0,988.327,32.379Zm10.15,16.1a1.784,1.784,0,0,1-2.524,0L992.8,45.328a8.365,8.365,0,1,1,2.524-2.523l3.149,3.149A1.784,1.784,0,0,1,998.477,48.477Z"
          transform="translate(-980 -30)"
        ></path>
      </svg>
    )}
    {type === 'chevron' && <img src="/images/top/img-arrow.png" alt="" width="18" height="28" />}
  </IconContainer>
)

Icon.defaultProps = {
  degree: 0,
}

const IconContainer = styled.span<Partial<IconProps>>`
  display: block;
  transform: rotate(${({ degree }) => String(degree)}deg);
  width: 100%;
  height: 100%;
  line-height: 1;

  path {
    fill: ${({ color }) => colorControl[color]};
  }
`
