import { VFC } from 'react'
import styled from 'styled-components'
import { HamburgerButtonProps } from '@/components/types'
import { breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'

export const HamburgerButton: VFC<HamburgerButtonProps> = ({ expanded, onClick }) => (
  <Button type="button" aria-label="メニューボタン" aria-expanded={expanded} onClick={onClick}>
    <span></span>
    <span></span>
    <span></span>
  </Button>
)

const Button = styled.button`
  position: relative;
  vertical-align: middle;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -1.2rem;
    left: -0.8rem;
    width: calc(100% + 1.6rem);
    height: calc(100% + 2.4rem);
  }

  span {
    display: flex;
    align-items: center;
    opacity: 1;
    position: relative;
    margin: auto;
    width: 3.8rem;
    height: 0.6rem;
    transition: transform 0.2s ease-out;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      border-radius: 100%;
      width: 0.7rem;
      height: 0.7rem;
      transition: transform 0.2s ease-out;
      transform-origin: center;
      background-color: ${colorConfig.themeColor};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      width: 2.6rem;
      height: 0.6rem;
      transition: transform 0.2s ease-out;
      transform-origin: center;
      background-color: ${colorConfig.themeColor};
    }

    + span {
      margin-top: 0.6rem;
    }
  }

  &[aria-expanded='true'] {
    span {
      &::before {
        opacity: 0;
      }

      &:nth-of-type(1) {
        &::after {
          top: 1.2rem;
          border-radius: 0;
          width: 4.2rem;
          transform: rotate(45deg);
        }
      }

      &:nth-of-type(2) {
        &::after {
          opacity: 0;
        }
      }

      &:nth-of-type(3) {
        &::after {
          top: -1.2rem;
          border-radius: 0;
          width: 4.2rem;
          transform: rotate(-45deg);
        }
      }
    }
  }
`
