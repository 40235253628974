import { FC } from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import { HamburgerButton } from '@/components/HamburgerButton'
import { HeaderProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'

export const Header: FC<HeaderProps> = ({ expanded, onClick, children }) => (
  <StyledHeader>
    <StyledLink to="/">
      <Logo>
        <img src="/images/logo.svg" alt="Ai-R ロゴ画像" width="70" height="43" />
      </Logo>
    </StyledLink>
    <Children>{children}</Children>
    <HamburgerButtonContainer>
      <HamburgerButton expanded={expanded} onClick={onClick} />
    </HamburgerButtonContainer>
  </StyledHeader>
)

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: ${zIndex.header};
  padding: 1.2rem calc(1rem + var(--scroll-bar-width)) 1.2rem 1rem;
  background-color: ${colorConfig.bgColor};
  width: 100%;
  border-bottom: 1px solid #fff1f1;

  ${breakpointUp('md')} {
    padding: 1.6rem calc(1.6rem + var(--scroll-bar-width)) 1.6rem 1.6rem;
  }
`

const StyledLink = styled(Link)`
  ${mixins.hoverDefault()};
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Children = styled.div`
  width: 100%;
`

const Logo = styled.div`
  width: 5rem;

  ${breakpointUp('md')} {
    width: 7rem;
  }
`

const HamburgerButtonContainer = styled.div`
  line-height: 1;

  ${breakpointUp('lg')} {
    display: none;
  }
`
