import { FC } from 'react'
import styled from 'styled-components'
import { NavigationProps } from '@/components/types'
import { colorConfig, zIndex } from '@/utils/styleConfig'

export const Navigation: FC<NavigationProps> = ({ expanded, children, onClick }) => (
  <NavigationContainer aria-hidden={!expanded}>
    <StyledNavigation>{children}</StyledNavigation>
    <NavigationBackdrop onClick={onClick} />
  </NavigationContainer>
)

const NavigationContainer = styled.div`
  position: fixed;
  z-index: ${zIndex.navigation};
  top: 5.4rem;
  left: 0;
  width: 100%;
  height: 100%;

  &[aria-hidden='true'] {
    pointer-events: none;
  }
`

const StyledNavigation = styled.div`
  overflow-y: auto;
  position: absolute;
  z-index: ${zIndex.navigation};
  top: 0;
  right: 0;
  padding: 2.4rem calc(2.4rem + var(--scroll-bar-width)) 2.4rem 2.4rem;
  background-color: ${colorConfig.themeColor};
  color: ${colorConfig.textSubColor};
  width: calc(22rem + var(--scroll-bar-width));
  height: calc(100% - 5.4rem);
  transition: transform 0.2s ease;
  transform: translateX(0);

  [aria-hidden='true'] & {
    transform: translateX(30rem);
  }
`

const NavigationBackdrop = styled.div`
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colorConfig.bgColor};
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease;

  [aria-hidden='true'] & {
    opacity: 0;
    pointer-events: none;
  }
`
