import { FC } from 'react'
import styled, { css } from 'styled-components'
import { LayoutContainerProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'

export const LayoutContainer: FC<LayoutContainerProps> = ({ children, bgColor }) => (
  <StyledLayoutContainer bgColor={bgColor}>
    <LayoutInner>{children}</LayoutInner>
  </StyledLayoutContainer>
)

LayoutContainer.defaultProps = {
  bgColor: undefined,
}

const StyledLayoutContainer = styled.div<Partial<LayoutContainerProps>>`
  ${({ bgColor }) =>
    bgColor === 'attention'
      ? css`
          background-color: ${colorConfig.bgAttentionColor};
        `
      : bgColor === 'subColor'
      ? css`
          background-color: ${colorConfig.bgSubColor};
        `
      : ''}
`

const LayoutInner = styled.div`
  ${breakpointDown('sm')} {
    margin: 0 2.5rem;
  }

  ${breakpointUp('md')} {
    max-width: calc(1024px + 10%);
    padding: 0 5%;
    margin: auto;
  }
`
