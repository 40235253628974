import { VFC } from 'react'
import { Helmet } from 'react-helmet'
import { metaData } from '@/feature/staticData'
import { SeoProps } from '@/components/types'

export const Seo: VFC<SeoProps> = ({ meta = [], page = 'template', title = '', url = '' }) => {
  const metaTitle =
    page === 'template'
      ? title.concat(metaData[page].title)
      : page === 'yougo'
      ? title.concat('｜').concat(metaData[page].title).concat(metaData['template'].title)
      : metaData[page].title.concat(metaData['template'].title)
  const metaDescription = metaData[page].description
  const metaUrl =
    page === 'template'
      ? metaData[page].url.concat(url)
      : page === 'yougo'
      ? metaData['template'].url.concat(url)
      : metaData['template'].url.concat(metaData[page].url)

  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja',
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        },
      ].concat(meta)}
    />
  )
}
