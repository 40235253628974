import { createGlobalStyle } from 'styled-components'
import { breakpoints, rootFontSize, colorConfig } from '@/utils/styleConfig'
import { breakpointUp } from '@/utils/breakpoints'

const breakpointsKeys = Object.keys(breakpoints)

const setRootFontSize = () => {
  let responsiveRootFontSize = ''

  breakpointsKeys.forEach((breakpoint: keyof typeof breakpoints) => {
    responsiveRootFontSize += `${breakpointUp(breakpoint)} {
      font-size: ${rootFontSize[breakpoint]}
    }`
  })

  return responsiveRootFontSize
}

export const BaseStyle = createGlobalStyle`
:root {
  ${setRootFontSize()};
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  transition: background-color 0.2s ease, color 0.2s ease;
  background-color: ${colorConfig.bgColor};
  color: ${colorConfig.textMainColor};
  font-family: tbudrgothic-std, sans-serif;
  letter-spacing: 0.1em;
  word-wrap: break-word;
  --scroll-bar-width: 0;
}

body {
  font-size: 1.6rem;
  line-height: 1.8;
  padding-right: var(--scroll-bar-width);
}

::-moz-selection {
  background-color: ${colorConfig.themeColor};
  color: ${colorConfig.textSubColor};
  text-shadow: none;
}

::selection {
  background-color: ${colorConfig.themeColor};
  color: ${colorConfig.textSubColor};
  text-shadow: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

ul, ol {
  list-style: none;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline-width: 1px;
}
`
