export const metaData = {
  template: {
    title: '｜株式会社Ai-R（アイアール）',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: 'http://www.ai-r.kyoto.jp',
  },
  top: {
    title: 'あったらいいなで「楽」を創造する',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/',
  },
  display: {
    title: 'ディスプレイ事業部',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/service/display/',
  },
  'display-detail': {
    title: 'ディスプレイ事業部 製品が出来るまでの流れ',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/service/display/detail/',
  },
  it: {
    title: '情報機器事業部',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/service/it/',
  },
  'it-detail': {
    title: '情報機器事業部 製品が出来るまでの流れ',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/service/it/detail/',
  },
  new: {
    title: '新規事業部',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/service/new/',
  },
  company: {
    title: '会社概要',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/company/',
  },
  case: {
    title: '事例紹介',
    description: '',
    url: '/case/',
  },
  recruit: {
    title: '採用情報',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/recruit/',
  },
  contact: {
    title: 'お問い合わせ',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/contact/',
  },
  thanks: {
    title: 'お問い合わせ完了',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/thanks/',
  },
  privacy: {
    title: 'プライバシーポリシー',
    description:
      '私たちはミュージアムやイベントなどの展示装置と先進のオフィス関連情報機器を企画・設計・製作する創業130年の京都のメーカーです。',
    url: '/privacy/',
  },
  sitemap: {
    title: 'サイトマップ',
    description: '',
    url: '/sitemap/',
  },
  yougo: {
    title: '用語集',
    description: '',
    url: '/yougo/a/',
  },
  '404': {
    title: '404: Not found',
    description: '404: Not found',
    url: '/404/',
  },
}

export const caseTags = {
  facility: [
    '科学館・博物館',
    'テーマパーク',
    'PR館（企業SR）',
    '商業施設',
    '展示会・イベント',
    '教育施設',
    'その他施設',
  ],
  item: [
    '科学実験装置',
    '科学実験装置（力）',
    '科学実験装置（光）',
    '科学実験装置（音）',
    'スポーツ体験・健康装置・ゲーム',
    '防災体験装置',
    'モニュメント ・からくり・装置',
    '設備・昇降装置',
    'エネルギー・リサイクル関連装置',
    'ディタッチャー',
    'インサータ',
    'ラミネーター',
  ],
  original: [
    'Ai-Rオリジナル',
    'スポーツ体験（オリジナル）',
    '科学実験装置（オリジナル）',
    'オーダー商品',
  ],
  industry: ['情報機器事業部', 'ディスプレイ事業部'],
}
